<template>
  <div>
    <div
      class="modal fade"
      id="modalSendCaseToOrg"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalSendCaseToOrgLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="d-flex justify-content-between p-3">
            <h5
              class="modal-title"
              id="modalSendCaseToOrgLabel"
            >{{$t('multidisciplinary_board.lbl_submit_org')}}</h5>
            <button
              type="button"
              class="close bg-white border-0"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" class="robo-18-500">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label
                for="room"
                class="required fs-16 mb-1"
              >{{$t('multidisciplinary_board.lbl_mdt_room')}}</label>
              <select id="room" class="form-select bg-white border" v-model="form.public_mdt_id">
                <option :value="null">{{$t('multidisciplinary_board.lbl_select_room')}}</option>
                <option v-for="r in public_mdts" :value="r.id" :key="r.id + 'room'">{{r.name}}</option>
              </select>
            </div>
            <div class="mb-3">
              <label
                for="date"
                class="required fs-16 mb-1"
              >{{$t('multidisciplinary_board.lbl_mdt_date')}}</label>
              <input id="date" type="date" v-model="form.date" class="form-control bg-white border" />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn bg-pri text-white"
              @click="submit"
              :disabled="loading"
            >Gửi</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalSendCaseToOrg',
  props: ['mdt_disease'],
  data () {
    return {
      public_mdts: [],
      form: {
        mdt_room_diseases_id: null,
        date: window.moment().format('YYYY-MM-DD'),
        patient_name: '',
        patient_gender: 1,
        patient_birthday: '',
        patient_diagnostic: '',
        patient_address: '',
        summary_medical: '',
        surgical_team: '',
        surgery_desc: '',
        public_mdt_id: null,
        type: 0,
        code: '',
        admission_date: '',
        indication: ''
      },
      loading: false
    }
  },
  watch: {
    mdt_disease (d) {
      if (d) {
        this.form = {
          mdt_room_diseases_id: d.id,
          date: window.moment().format('YYYY-MM-DD'),
          patient_name: d.patient_name,
          patient_gender: d.patient_gender,
          patient_birthday: d.patient_birthday,
          patient_diagnostic: d.patient_diagnostic,
          patient_address: d.patient_address,
          summary_medical: d.patient_diseases_current_cure,
          surgical_team: d.operation?.surgical_team || null,
          surgery_date: d.operation?.surgery_date || null,
          public_mdt_id: null,
          type: d.type,
          code: d.code,
          admission_date: d.admission_date,
          indication: d.indication
        }
      } else {
        this.form = {
          mdt_room_diseases_id: null,
          date: window.moment().format('YYYY-MM-DD'),
          patient_name: '',
          patient_gender: 1,
          patient_birthday: '',
          patient_diagnostic: '',
          summary_medical: '',
          surgical_team: '',
          surgery_desc: '',
          public_mdt_id: null,
          type: 0,
          code: '',
          indication: '',
          admission_date: ''
        }
      }
    }
  },

  methods: {
    async getPublicMtdRooms () {
      const params = {
        organization_id: this.$globalClinic?.org_id,
        page: 1,
        limit: 200
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .getPublicMtdRooms(params)
        .then(r => {
          this.public_mdts = r?.data?.data
        })
    },
    async submit () {
      let self = this
      if (!this.form.mdt_room_diseases_id) {
        alert("$t('multidisciplinary_board.lbl_select_room_alert')")
        return
      }
      if (!this.form.date) {
        alert("$t('multidisciplinary_board.lbl_select_date_alert')")
      }
      this.loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .createPublicMdtCase(this.form)
        .then(r => {
          self.$router.push({
            name: 'OrganizationMdtDiseaseDetail',
            params: { id: r.data.id }
          })
          self.form = {
            mdt_room_diseases_id: null,
            date: window.moment().format('YYYY-MM-DD'),
            patient_name: '',
            patient_gender: 1,
            patient_birthday: '',
            patient_diagnostic: '',
            summary_medical: '',
            surgical_team: '',
            surgery_desc: '',
            public_mdt_id: null,
            type: 0,
            code: '',
            indication: '',
            admission_date: ''
          }
        })
        .finally(() => {
          self.loading = false
        })
    },
    openModal (show) {
      if (show) {
        this.getPublicMtdRooms()
      }

      window.$('#modalSendCaseToOrg').modal(show ? 'show' : 'hide')
    }
  }
}
</script>

<style scoped>
</style>
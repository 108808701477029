<template>
  <div>
    <div
      class="modal fade"
      id="modalAddDisease"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalAddDiseaseLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog"
        role="document"
      >
        <div
          class="modal-content"
          v-show="!show_add_file && !show_add_related"
        >
          <div class="d-flex justify-content-between p-3">
            <h5
              class="modal-title"
              id="modalAddDiseaseLabel"
            >{{$t('multidisciplinary_board.btn_add_case')}}</h5>
            <button
              type="button"
              class="close bg-white border-0"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                aria-hidden="true"
                class="robo-18-500"
              >&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mt-2">
              <div class="col-sm-12">
                <div style="padding: 15px 15px;">
                  <div class="row">
                    <SelectDisease
                      @selectedDisease="selectedDisease"
                      @selectedPerson="selectedPerson"
                      :person="slt_person"
                    />

                    <div
                      class="mb-3"
                      v-if="slt_disease"
                    >
                      <div class="row">
                        <div class="col-12">
                          <p class="robo-16-400 txt-black">{{getPersonInfoString(slt_disease.person)}}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          <p class="robo-16-400 txt-black">{{$t('multidisciplinary_board.lbl_diagnose')}}: </p>
                        </div>
                        <div class="col-8">
                          <p class="robo-16-400 txt-black">{{slt_disease.diseases_name}}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          <p class="robo-16-400 txt-black">Tình trạng hiện tại:</p>
                        </div>
                        <div class="col-8">
                          <p
                            class="robo-16-400 txt-black"
                            style="white-space: pre-wrap;"
                          >{{slt_disease.diseases_current_cure}}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          <p class="robo-16-400 txt-black">Điều trị:</p>
                        </div>
                        <div class="col-8">
                          <p
                            class="robo-16-400 txt-black"
                            style="white-space: pre-wrap;"
                          >{{slt_disease.diseases_previous_cure}}</p>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <p class="robo-18-400 txt-grey-600">Mô tả</p>
                      <textarea
                        v-model="description"
                        class="form-control border border-2 bg-white"
                        rows="5"
                        placeholder="Nhập nội dung ..."
                      ></textarea>
                    </div>
                    <div class="mb-3">
                      <p class="robo-18-400 txt-grey-600">Các ca bệnh tương tự</p>
                      <div
                        class="add-relate-btn mb-3"
                        @click="showAddRelate(true)"
                      >
                        <svg
                          width="33"
                          height="32"
                          viewBox="0 0 33 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="mr-2"
                        >
                          <path
                            d="M16.4359 29.3332C23.7692 29.3332 29.7692 23.3332 29.7692 15.9998C29.7692 8.6665 23.7692 2.6665 16.4359 2.6665C9.10254 2.6665 3.10254 8.6665 3.10254 15.9998C3.10254 23.3332 9.10254 29.3332 16.4359 29.3332Z"
                            stroke="#20419B"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.1025 16H21.7692"
                            stroke="#20419B"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M16.436 21.3332V10.6665"
                            stroke="#20419B"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <p class="mb-0 txt-pri robo-16-400">{{$t('multidisciplinary_board.btn_add')}}</p>
                      </div>
                      <div
                        class="related-item border mb-3"
                        v-for="related in related_diseases"
                        :key="'related'+related.id"
                      >
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="mr-2"
                        >
                          <path
                            d="M8.43604 12.2002H15.436"
                            stroke="#20419B"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.43604 16.2002H12.816"
                            stroke="#20419B"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10.436 6H14.436C16.436 6 16.436 5 16.436 4C16.436 2 15.436 2 14.436 2H10.436C9.43604 2 8.43604 2 8.43604 4C8.43604 6 9.43604 6 10.436 6Z"
                            stroke="#20419B"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M16.436 4.02002C19.766 4.20002 21.436 5.43002 21.436 10V16C21.436 20 20.436 22 15.436 22H9.43604C4.43604 22 3.43604 20 3.43604 16V10C3.43604 5.44002 5.10604 4.20002 8.43604 4.02002"
                            stroke="#20419B"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <p class="robo-16-400 txt-black mb-0 max-line-1">{{related.diseases_name}} - {{getPersonInfoString(related.person)}}</p>
                      </div>
                    </div>
                    <!-- <div class="mb-3" v-show="room && room.repeat_type">
                      <div class="d-flex align-items-center">
                        <p class="robo-18-400 txt-grey-600 mb-0 mr-2">Thời gian hội chẩn</p>

                        <input
                          id="datePickDisease"
                          v-model="date"
                          class="radius-10 border input-date"
                        >
                      </div>
                    </div> -->
                    <div class="mb-3">
                      <div class="d-flex align-items-center mb-3">
                        <p class="robo-18-400 txt-grey-600 mb-0 mr-4">Tài liệu đính kèm</p>
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="cursor-pointer mr-4"
                          @click="openPasteLink"
                        >
                          <path
                            d="M15.4258 17.5H16.9358C19.9558 17.5 22.4358 15.03 22.4358 12C22.4358 8.98 19.9658 6.5 16.9358 6.5H15.4258"
                            stroke="#292D32"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M9.43585 6.5H7.93585C4.90585 6.5 2.43585 8.97 2.43585 12C2.43585 15.02 4.90585 17.5 7.93585 17.5H9.43585"
                            stroke="#292D32"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.43585 12H16.4359"
                            stroke="#292D32"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="cursor-pointer"
                          @click="onOpenPickFile"
                        >
                          <path
                            d="M12.4958 16.5V11.5"
                            stroke="#292D32"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14.9359 14H9.93585"
                            stroke="#292D32"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M22.4359 11V17C22.4359 21 21.4359 22 17.4359 22H7.43585C3.43585 22 2.43585 21 2.43585 17V7C2.43585 3 3.43585 2 7.43585 2H8.93585C10.4359 2 10.7659 2.44 11.3359 3.2L12.8359 5.2C13.2159 5.7 13.4359 6 14.4359 6H17.4359C21.4359 6 22.4359 7 22.4359 11Z"
                            stroke="#292D32"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                          />
                        </svg>
                      </div>
                      <div v-if="file_data && file_data.length">
                        <div
                          v-for="item in file_data"
                          :key="item.id"
                          class="p-2 border radius-10 mb-2"
                        >
                          <a
                            v-if="item.url"
                            :href="item.url"
                            target="_blank"
                            class="d-flex align-items-center txt-pri text-decoration-underline"
                          ><svg
                              width="25"
                              height="24"
                              viewBox="0 0 25 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="mr-2"
                            >
                              <path
                                d="M15.4258 17.5H16.9358C19.9558 17.5 22.4358 15.03 22.4358 12C22.4358 8.98 19.9658 6.5 16.9358 6.5H15.4258"
                                stroke="#292D32"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9.43585 6.5H7.93585C4.90585 6.5 2.43585 8.97 2.43585 12C2.43585 15.02 4.90585 17.5 7.93585 17.5H9.43585"
                                stroke="#292D32"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M8.43585 12H16.4359"
                                stroke="#292D32"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            {{item.name}}
                          </a>
                          <div
                            v-if="item.file"
                            class="d-flex align-items-center"
                          >
                            <svg
                              width="25"
                              height="24"
                              viewBox="0 0 25 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="mr-2"
                            >
                              <path
                                d="M22.4359 11V17C22.4359 21 21.4359 22 17.4359 22H7.43585C3.43585 22 2.43585 21 2.43585 17V7C2.43585 3 3.43585 2 7.43585 2H8.93585C10.4359 2 10.7659 2.44 11.3359 3.2L12.8359 5.2C13.2159 5.7 13.4359 6 14.4359 6H17.4359C21.4359 6 22.4359 7 22.4359 11Z"
                                stroke="#292D32"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                              />
                            </svg>
                            <span>{{item.file.name}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center mb-3">
            <button
              :disabled="loading"
              v-if="can('create-new-patient')"
              class="btn radius-0 bg-pri bd-pri text-white border-0 px-5"
              type="button"
              @click="onSubmit"
            >{{$t('multidisciplinary_board.btn_save')}}
              <span
                class="spinner-border spinner-border-sm mr-2"
                role="status"
                v-if="loading"
              ></span>
            </button>
          </div>
        </div>
        <div
          class="modal-content"
          v-show="show_add_file"
        >
          <div class="d-flex justify-content-between p-3">
            <h5
              class="modal-title"
              id="modalAddDiseaseLabel"
            >Thêm tài liệu </h5>
            <button
              type="button"
              class="close bg-white border-0"
              @click="showAddFile(false)"
            >
              <span
                aria-hidden="true"
                class="robo-18-500"
              >&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label class="robo-18-400 txt-grey-600 mb-1 mr-2 required">Tên tài liệu</label>

              <input
                v-model="input_file.name"
                class="radius-10 border input-date w-100"
              >

            </div>
            <div class="mb-3">
              <label class="robo-18-400 txt-grey-600 mb-1 mr-2 required">Tài liệu</label>

              <input
                v-if="input_file_type === 'link'"
                v-model="input_file.url"
                class="radius-10 border input-date w-100"
                placeholder="Dán link tài liệu ở đây"
              >
              <!-- <p
                class="robo-18-400 txt-grey-600 mb-2 mr-2"
                v-for="file in input_file.files"
                :key="file.name"
              >{{file.name}}</p> -->
              <p
                class="robo-18-400 txt-grey-600 mb-2 mr-2"
                v-if="input_file.file && input_file.file.name"
              >{{input_file.file.name}}</p>
              <button
                v-if="input_file_type === 'file' && !input_file.file"
                class="btn bg-pri bd-pri text-white mb-3"
                @click="openPickFile"
              >Chọn file</button>

            </div>
          </div>
          <div class="d-flex justify-content-center mb-3">
            <button
              type="button"
              class="btn bg-pri bd-pri text-white"
              @click="onAddFileToDisease"
            >OK</button>
          </div>
        </div>
        <div
          class="modal-content"
          v-show="show_add_related"
        >
          <div class="d-flex justify-content-between p-3">
            <h5
              class="modal-title"
              id="modalAddDiseaseLabel"
            >{{$t('multidisciplinary_board.btn_add_case')}}</h5>
            <button
              type="button"
              class="close bg-white border-0"
              @click="showAddRelate(false)"
            >
              <span
                aria-hidden="true"
                class="robo-18-500"
              >&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <SelectDisease
              @selectedDisease="selectedDisease"
              @selectedPerson="()=>{}"
              :refresh_disease="refresh_realted_input_disease"
              :person="slt_person"
              :hide_select_person="true"
            />
          </div>
          <div class="d-flex justify-content-center mb-3">
            <button
              :disabled="loading"
              v-if="can('create-new-patient')"
              class="btn radius-0 bg-pri bd-pri text-white border-0 px-5"
              type="button"
              @click="addRelated"
            >{{$t('multidisciplinary_board.btn_add')}}
              <span
                class="spinner-border spinner-border-sm mr-2"
                role="status"
                v-if="loading"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="addLinkToMdtDisease"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addLinkToMdtDiseaseLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog"
        role="document"
      >
      </div>
    </div>
    <input
      id="file-input"
      name='file-input'
      type="file"
      class="d-none"
      accept=".pdf,.docx,.doc,.xlsx,.xls,.jpeg,.jpg,.gif,.png,.mp4,.ogx,.oga,.ogv,.ogg,.webm"
      ref="inputFile"
      @change="onSelectSingleFile($event)"
    >
  </div>
</template>


<script>
import SelectDisease from './SelectDisease.vue'
export default {
  name: 'ModalAddDisease',
  props: ['room', 'showing_date'],
  components: { SelectDisease },
  watch: {
    room (room) {
      this.changeRoom(room)
    }
  },
  data () {
    return {
      loading: false,
      slt_disease: null,
      slt_person: null,
      description: '',
      date: window.moment().format('DD-MM-YYYY'),
      input_file: {
        name: '',
        files: [],
        file: null,
        url: ''
      },
      file_data: [],
      input_file_type: 'link',
      show_add_file: false,
      show_add_related: false,
      slt_related: null,
      related_diseases: [],
      refresh_realted_input_disease: 0
    }
  },
  mounted () {
    let self = this
    window.$(document).ready(function () {
      window.$('#modalAddDisease').on('shown.bs.modal', function (e) {
        self.show_add_file = false
        self.show_add_related = false
      })
      window.$('#modalAddDisease').on('hide.bs.modal', function (e) {
        self.related_diseases = []
        self.slt_disease = null
        self.input_file = {
          name: '',
          files: [],
          url: '',
          file: null
        }
        self.file_data = []
        self.show_add_file = false
        self.show_add_related = false
      })
    })
  },
  methods: {
    selectedDisease (d) {
      if (!this.show_add_related) {
        this.slt_disease = d
        this.slt_person = d.person
      } else {
        this.slt_related = d
      }
    },
    selectedPerson (p) {
      if (!this.show_add_related) {
        this.slt_person = p
      }
    },
    async onSubmit () {
      if (!this.slt_disease) {
        alert('Vui lòng chọn hồ sơ sức khỏe')
        return false
      }
      try {
        let r = await this.addMdtDiseases()
        console.log(r)
      } catch (error) {

      } finally {
        this.$emit('refresh')
      }
    },
    async addMdtDiseases () {
      let self = this
      let params = {
        mdt_room_id: self.room.id,
        description: self.description,
        person_diseases_id: self.slt_disease.id,
        // date: self.date,
        status: 0
      }
      if (this.related_diseases && this.related_diseases.length) {
        let ids = []
        this.related_diseases.forEach(d => ids.push(d.id))
        params.related_mdt_diseases = ids
      }
      // if (!self.room.repeat_type) {
      //   params.date = window.moment(self.room.date, 'YYYY-MM-DD').format('DD-MM-YYYY')
      // }
      let r = await self.$rf.getRequest('DoctorRequest').addMdtDiseases(params)
      console.log(r)
      if (this.file_data && this.file_data.length && r?.data?.id) {
        let rf = this.onSubmitFiles(r?.data?.id)
        return rf
      } else {
        return r
      }
    },
    changeRoom (room) {
      // if (!room) return
      // let self = this
      // let day = null
      // if (room.repeat_type) {
      //   if (room.repeat_type === 2) {
      //     day = window.moment(room.date).day()
      //   }
      //   self.date = self.showing_date.format('DD-MM-YYYY')
      //   window.flatpickr(document.getElementById('datePickDisease'), {
      //     defaultDate: self.date,
      //     minDate: new Date(),
      //     dateFormat: 'd-m-Y',
      //     disable: [function (date) {
      //       var dayOfThis = date.getDay()
      //       return day ? dayOfThis !== day : false
      //     }]
      //   })
      // } else {
      //   self.date = room.date
      // }
    },
    getPersonInfoString (p) {
      return p.name + ' (' + this.getGender(p.gender) + ' - ' + this.getAges(p.birthday) + ' - ' + p.address + ')'
    },
    getGender (gender = 1) {
      return gender === 1 ? 'Nam' : gender === 2 ? 'Nữ' : gender === 3 ? 'Khác' : ''
    },
    getAges (date) {
      let bd = window.moment(date)
      let age = window.moment().diff(bd, 'years')
      return age + ' tuổi'
    },
    openPasteLink () {
      this.input_file_type = 'link'
      this.showAddFile(true)
    },
    onOpenPickFile () {
      this.input_file_type = 'file'
      this.showAddFile(true)
      this.openPickFile()
    },
    openPickFile () {
      window.$('#file-input').trigger('click')
    },
    onSelectFile (event) {
      let self = this
      let _files = !!self.input_file.files && !!self.input_file.files.length ? self.input_file.files : []
      const files = event.target.files
      if (files) {
        for (let i = 0; i < files.length; i++) {
          const item = files[i]
          if (item) {
            if (item.name.toLowerCase().match(/\.(jpeg|jpg|gif|png|mp4|ogx|oga|ogv|ogg|webm|pdf|docx|doc|xlsx|xls)$/) != null) {
              _files.push(item)
            } else {
              self.$toast.open({
                message: 'Không thể chọn tệp ' + item.name + ' do không hợp lệ',
                type: 'warning'
              })
            }
          }
        }
      }
      self.input_file.files = _files
      setTimeout(() => {
        this.$refs.inputFile.value = null
      }, 100)
    },
    onSelectSingleFile (event) {
      const item = event.target.files[0]
      console.log(item)
      if (item) {
        if (item.name.toLowerCase().match(/\.(jpeg|jpg|gif|png|mp4|ogx|oga|ogv|ogg|webm|pdf|docx|doc|xlsx|xls)$/) != null) {
          this.input_file.file = item
        } else {
          this.$toast.open({
            message: 'Không thể chọn tệp ' + item.name + ' do không hợp lệ',
            type: 'warning'
          })
        }
      }
      setTimeout(() => {
        this.$refs.inputFile.value = null
      }, 100)
    },
    showAddFile (show) {
      this.show_add_file = !!show
      if (!show) {
        this.input_file = {
          name: '',
          files: [],
          file: null,
          url: ''
        }
      }
    },
    onAddFileToDisease () {
      let self = this
      if (self.input_file.name && (self.input_file.url || self.input_file.file)) {
        let data = self.input_file
        if (self.input_file.url) {
          data.type = 2
        }
        if (self.input_file.file) {
          data.type = 1
        }
        self.file_data.push(data)
        self.showAddFile()
        setTimeout(() => {
          self.input_file = {
            name: '',
            files: [],
            url: '',
            file: null
          }
        }, 50)
      } else {
        alert('Vui lòng nhập đủ thông tin có dấu *')
      }
    },
    async onSubmitFiles (id) {
      let self = this
      let params = {
        mdt_room_disease_id: id
      }
      const formData = new FormData()
      for (var key in params) {
        formData.append(key, params[key])
      }
      self.file_data.forEach((file, i) => {
        console.log(file.file)
        formData.append('data[' + i + '][name]', file.name || null)
        file.file && formData.append('data[' + i + '][file]', file.file)
        formData.append('data[' + i + '][type]', file.type || null)
        file.url && formData.append('data[' + i + '][url]', file.url)
      })
      await self.$rf.getRequest('DoctorRequest').addMdtDiseasesFile(formData).then((r) => {
        return r
      })
    },
    showAddRelate (show) {
      this.show_add_related = !!show
      if (!show) {
        this.slt_related = null
        this.refresh_realted_input_disease++
      }
    },
    addRelated () {
      if (this.slt_related) {
        this.related_diseases.push(this.slt_related)
      }
      this.refresh_realted_input_disease++
      this.showAddRelate()
    }
  }
}
</script>
<style lang="css" scoped>
.input-date {
  height: 40px !important;
  font-size: 16px !important;
  color: black !important;
  padding-left: 5px !important;
}
.add-relate-btn {
  display: flex;
  align-items: center;
  height: 48px;
  border: 1px dashed #20419B;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
}

.related-item {
  display: flex;
  align-items: center;
  height: 48px;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
}
</style>
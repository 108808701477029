<template>
  <div class="box-container container">
    <div class="absolute cursor-pointer d-print-none top-2 right-2" @click="handleBack">
      <div>
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="mx-1"
        >
          <path
            d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
            fill="black"
          />
        </svg>
      </div>
    </div>
    <div class="marin-print"></div>
    <div class="px-8">
      <div class="font-style mb-3">
        <div class="row flex w-full justify-between items-center mx-auto">
          <div class="col-6">
            <div class="text-center">
              <!-- <div>{{ org.aic }}</div> -->
              <div>{{ org.org_name }}</div>
              <div>{{ org.name }}</div>
            </div>
          </div>
          <div class="col-6">
            <div class="text-center">
              <div>{{$t('multidisciplinary_board.lbl_operation_list')}}</div>
              <div>{{ date }}</div>
            </div>
          </div>
          <!-- <div class="col-1">
  
          </div>-->
        </div>
      </div>
      <table class="mb-10">
        <tr class="font-style">
          <th width="2%">{{$t('multidisciplinary_board.lbl_seq')}}</th>
          <th width="3%">{{$t('multidisciplinary_board.lbl_emr_code')}}</th>
          <th width="17.5%">{{$t('multidisciplinary_board.lbl_fullname')}}</th>
          <th width="3%">{{$t('multidisciplinary_board.lbl_boy')}}</th>
          <th width="7%">{{$t('multidisciplinary_board.lbl_admissions_date')}}</th>
          <th width="17.5%">{{$t('multidisciplinary_board.lbl_diagnose')}}</th>
          <th width="25%">{{$t('multidisciplinary_board.lbl_surgery_method')}}</th>
          <th width="19%">{{$t('multidisciplinary_board.lbl_surgery_team')}}</th>
          <!-- <th width="10%">Thông qua</th> -->
          <th width="6%">{{$t('multidisciplinary_board.lbl_note')}}</th>
        </tr>
        <tr class="table-row" v-for="(item, i) in mdtList" :key="i+'danhsachmo'">
          <td>{{i+1}}</td>
          <td>{{ getEMRCode(item) }}</td>
          <td>{{ item.patient_name }}</td>
          <td>{{ formatYear(item.patient_birthday) }}</td>
          <td>{{ item.admission_date }}</td>
          <td>{{ getDiagnose(item) }}</td>
          <td v-html="(item.operation && item.operation.surgery_method) || item.surgery_method" />
          <td v-html="(item.operation && item.operation.surgical_team) || item.surgical_team" />
          <!-- <td>{{ item.status }}</td> -->
          <!-- <td v-html="(item.operation && item.operation.prognostic) || item.prognostic" /> -->
          <td style="max-width:10%">
            <el-input
              class="cs-input py-1 d-print-none"
              type="textarea"
              v-model="item.operation.prognostic"
            />
            <!-- <el-input class="cs-input py-1" type="textarea" v-model="item.operation.prognostic" /> -->
            <span
              class="cs-note"
              v-html="(item.operation && item.operation.prognostic) || item.prognostic"
            ></span>
          </td>
        </tr>
      </table>
      <div class="box-footer">
        <div>
          <div class="font-style">{{$t('multidisciplinary_board.lbl_dept_leader')}}</div>
          <div class="mt-20">
            <div class="sign-text">{{form.input1}}</div>
            <input class="sign-input" v-model="form.input1" type="text" />
          </div>
        </div>
        <div>
          <div class="font-style">{{$t('multidisciplinary_board.lbl_subcommittee')}}</div>
          <div class="mt-20">
            <div class="sign-text">{{form.input2}}</div>
            <input class="sign-input" v-model="form.input2" type="text" />
          </div>
        </div>
        <div>
          <div class="font-style">{{$t('multidisciplinary_board.lbl_planning_dept')}}</div>
          <div class="mt-20">
            <div class="sign-text">{{form.input3}}</div>
            <input class="sign-input" v-model="form.input3" type="text" />
          </div>
        </div>
        <div>
          <div class="font-style">{{$t('multidisciplinary_board.lbl_mdt_president')}}</div>
          <div class="mt-20">
            <div class="sign-text">{{form.input4}}</div>
            <input class="sign-input" v-model="form.input4" type="text" />
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end my-5 d-print-none">
      <div class="d-flex">
        <button
          class="btn bg-white bd-pri ml-3"
          style="color: #20419b"
          @click="exportFile"
        >{{$t('multidisciplinary_board.btn_export_file')}}</button>
        <button
          class="btn bg-pri bd-pri text-white ml-3"
          @click="print"
        >{{$t('multidisciplinary_board.btn_print')}}</button>
      </div>
    </div>
  </div>
</template>
  
  <script>
import _ from 'lodash'
import mdtUtils from '../../utils/mdtUtils'
import moment from 'moment'
import ExcelJS from 'exceljs'

export default {
  name: 'OperationPrint',
  data () {
    return {
      form: {},
      mdtCasesData: [],
      mdtList: []
    }
  },
  watch: {
    tableData: {
      deep: true,
      handler (data) {
        this.mdtList = data
      }
    }
  },
  computed: {
    DoctorRequest () {
      return this.$rf.getRequest('DoctorRequest')
    },
    tableData () {
      const newMdtCase = (this.mdtCasesData || []).map(item => ({
        ...item,
        operation: {
          ...item?.operation,
          prognostic: item?.operation?.prognostic || ''
        }
      }))

      return _.chain(newMdtCase)
        .filter(e => e.type === 1)
        .map(item => {
          const admission_date = moment(item.admission_date)
          return {
            ...item,
            age: mdtUtils.getNumberAges(item.patient_birthday),
            admission_date: admission_date.isValid()
              ? admission_date.format('DD/MM/YYYY')
              : '',
            status: mdtUtils.getStatusPublicMdtCase(item.status)?.text
          }
        })
        .value()
    },
    date () {
      const dateQuery = this.$route.query.date
      const date = moment(dateQuery)
      return date.isValid()
        ? `${date.format('dddd')} ngày ${date.format('DD.MM.YYYY')}`
        : date
    },
    org () {
      const org = this.$globalClinic
      return {
        name: org?.name,
        org_name: org?.organization?.name,
        aic: org?.organization?.authority_in_charge
      }
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    formatYear (date) {
      return window.moment(date).format('YYYY')
    },
    async fetchData () {
      const dateQuery = this.$route.query.date

      try {
        const mdtRoomId = this.$route.params.id
        const params = {
          page: 1,
          limit: 10000,
          surgery_date: dateQuery,
          type: 1,
          status: 2
        }
        const res = await this.DoctorRequest.getMdtDiseaseByDate(
          mdtRoomId,
          params
        )
        this.mdtCasesData = res.data?.data || []
      } catch (error) {
        console.log(error)
      }
    },
    handleBack () {
      this.$router.push({
        name: 'ListMdt'
      })
    },
    print () {
      const tempTitle = document.title
      document.title = `Danh sach Benh nhan Mo_${moment().format(
        'DDMMYYYY'
      )}.pdf`
      window.print()
      document.title = tempTitle
    },
    async exportFile () {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Sheet1')

      // Access columns from B to J and set their widths
      const defaultColWidth = 22
      for (let index = 1; index <= 8; index++) {
        const column = worksheet.getColumn(index + 1) // Columns are 1-indexed
        column.width = defaultColWidth
      }

      const START_ROW = 2

      // Add a header rows and merged cells with style before main table
      const companyNameCell = worksheet.getCell(`B${START_ROW}`)
      companyNameCell.value = this.org.org_name
      companyNameCell.font = { size: 12, bold: true }
      worksheet.mergeCells(`B${START_ROW}:E${START_ROW}`)

      const wsNameCell = worksheet.getCell(`B${START_ROW + 1}`)
      wsNameCell.value = this.org.name
      wsNameCell.font = { size: 12, bold: true }
      worksheet.mergeCells(`B${START_ROW + 1}:E${START_ROW + 1}`)

      const titleNameCell = worksheet.getCell(`F${START_ROW}`)
      titleNameCell.value = 'DANH SÁCH BỆNH NHÂN MỔ'
      titleNameCell.font = { size: 12, bold: true }
      worksheet.mergeCells(`F${START_ROW}:I${START_ROW}`)

      const dateCell = worksheet.getCell(`F${START_ROW + 1}`)
      dateCell.value = this.getHMDMYVN(Date.now()).toUpperCase()
      dateCell.font = { bold: true }
      worksheet.mergeCells(`F${START_ROW + 1}:I${START_ROW + 1}`)

      worksheet.getRows(START_ROW, 2).forEach(row => {
        row.font = { size: 12, bold: true }
      })

      // Generate the main table (your data) with borders and style
      const rawData = this.mdtList.map((item, i) => {
        return [
          i + 1,
          this.getEMRCode(item) || '',
          item.patient_name || '',
          this.formatYear(item.patient_birthday) || '',
          item.admission_date || '',
          this.getDiagnose(item) || '',
          this.convertHtmlToPlainText(
            (item.operation && item.operation.surgery_method) ||
              item.surgery_method
          ),
          this.convertHtmlToPlainText(
            (item.operation && item.operation.surgical_team) ||
              item.surgical_team
          ),
          item.operation.prognostic || ''
        ]
      })
      const mainTableData = [
        [], // empty row
        [
          'STT',
          'MÃ BỆNH ÁN',
          'HỌ VÀ TÊN',
          'NĂM SINH',
          'NGÀY VÀO VIỆN',
          'CHẨN ĐOÁN',
          'CÁCH THỨC MỔ',
          'KÍP MỔ',
          'GHI CHÚ'
        ],
        ...rawData
      ]

      worksheet.addRows(mainTableData)
      const mainTable = worksheet.getRows(5, mainTableData.length - 1)
      mainTable.forEach((row, rowIndex) => {
        row.eachCell(cell => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
            bottom: { style: 'thin' }
          }
          cell.font = { size: 12 }
          if (!rowIndex) {
            cell.font = { bold: true }
          }
        })
      })

      // Footer
      const FOOTER_ROW_START = rawData.length + 8
      worksheet.getRow(FOOTER_ROW_START).font = { bold: true }
      worksheet.getCell(`B${FOOTER_ROW_START}`).value = 'LÃNH ĐẠO KHOA'
      worksheet.mergeCells(`B${FOOTER_ROW_START}:C${FOOTER_ROW_START}`)
      worksheet.getCell(`B${FOOTER_ROW_START + 4}`).value = this.form.input1
      worksheet.mergeCells(`B${FOOTER_ROW_START + 4}:C${FOOTER_ROW_START + 4}`)
      worksheet.getCell(`D${FOOTER_ROW_START}`).value = 'ĐẠI DIỆN TIỂU BAN'
      worksheet.mergeCells(`D${FOOTER_ROW_START}:E${FOOTER_ROW_START}`)
      worksheet.getCell(`D${FOOTER_ROW_START + 4}`).value = this.form.input2
      worksheet.mergeCells(`D${FOOTER_ROW_START + 4}:E${FOOTER_ROW_START + 4}`)
      worksheet.getCell(`F${FOOTER_ROW_START}`).value = 'PHÒNG KHTH'
      worksheet.mergeCells(`F${FOOTER_ROW_START}:G${FOOTER_ROW_START}`)
      worksheet.getCell(`F${FOOTER_ROW_START + 4}`).value = this.form.input3
      worksheet.mergeCells(`F${FOOTER_ROW_START + 4}:G${FOOTER_ROW_START + 4}`)
      worksheet.getCell(`H${FOOTER_ROW_START}`).value = 'CHỦ TRÌ THÔNG QUA MỔ'
      worksheet.mergeCells(`H${FOOTER_ROW_START}:I${FOOTER_ROW_START}`)
      worksheet.getCell(`H${FOOTER_ROW_START + 4}`).value = this.form.input4
      worksheet.mergeCells(`H${FOOTER_ROW_START + 4}:I${FOOTER_ROW_START + 4}`)

      // default styles
      workbook.eachSheet(sheet => {
        sheet.eachRow(row => {
          row.alignment = {
            horizontal: 'center',
            vertical: 'middle',
            wrapText: true
          }
          row.eachCell(cell => {
            if (!cell.font?.name) {
              cell.font = Object.assign(cell.font || {}, {
                name: 'Times New Roman',
                size: 12
              })
            }
          })
        })
      })
      worksheet.properties.defaultRowHeight = 30

      // Save the workbook to a file
      await workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'DS mổ.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },
    getHMDMYVN (date) {
      const string = this.moment(date).locale(this.$lang)
      if (this.$lang === 'vi') {
        // let hm = dt.clone().format('HH:mm')
        const dddd = string.clone().format('dddd')
        const d = string.clone().format('DD')
        const m = string.clone().format('MM')
        const y = string.clone().format('YYYY')
        return dddd + ' Ngày ' + d + ' tháng ' + m + ' năm ' + y
      } else {
        return string.format('LLL')
      }
    },
    convertHtmlToPlainText (html) {
      // Create a new div element
      if (!html) return ''
      let tempDivElement = document.createElement('div')
      // Set the HTML content with the given value
      tempDivElement.innerHTML = html
      // Retrieve the text property of the element
      return tempDivElement.textContent || tempDivElement.innerText || ''
    },
    getPid (data) {
      return (
        data?.medical_record?.person_ref_id ||
        (data?.medical_record?.person && data?.medical_record?.person?.pid) ||
        data?.operation?.code ||
        data?.concluding?.profile_code
      )
    },
    getDiagnose (item) {
      return (
        (item.operation && item?.operation?.diagnostic) ||
        (item.multilang && item?.multilang[0]?.patient_diagnostic) ||
        item?.patient_diagnostic
      )
    },
    getEMRCode (item) {
      return item?.medical_record?.ref_id || item?.person?.doc_no
    }
  }
}
</script>
  
  <style scoped lang="scss">
.box-container {
  color: black;
  margin: auto;
  ::v-deep * {
    font-family: "Times New Roman", "Times", "Tinos", serif !important;
  }
}
.box-footer {
  display: grid;
  grid-auto-flow: column;
  text-align: center;
}
.font-style {
  color: black;
  font-weight: bold;
  font-size: 13px;
  text-transform: uppercase;
}
table,
th,
td {
  color: black;
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
}
table {
  width: 100%;
}
.table-row > td {
  padding: 0 5px;
}
.table-row,
.sign-text {
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
}
.sign-input {
  outline: 0;
  border-width: 0 0 1px;
  text-align: center;
}
.sign-text {
  display: none;
}

.marin-print {
  margin-top: 80px;
}

.cs-note {
  display: none;
}

@media print {
  #BrowserPrintDefaults {
    display: none;
  }

  thead,
  tfoot {
    display: none !important;
  }

  @page {
    size: auto A4 landscape;
    margin: 0;
    margin-bottom: 20mm;
    margin-top: 20mm;
  }

  .container {
    max-width: unset;
    width: 1150px;
  }
  .sign-input {
    display: none;
  }
  .sign-text {
    display: block;
    font-weight: bold;
  }
  .word-break {
    word-break: break-all;
  }
  .marin-print {
    display: none;
  }

  .cs-note {
    display: block;
  }
}

::v-deep {
  .cs-input {
    input {
      background-color: #dae8fd !important;
      padding: 4px;
    }
  }
}
</style>
  